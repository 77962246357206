export default {
  steps: {
    initialCompanyInfo: 'Initial Company Info',
    teamInformation: 'Team Information',
    businessModel: 'Business Model',
    productAndMarket: 'Product and Market',
    ipAndLegal: 'IP and Legal',
  },

  dashboard: {
    valuation: 'Valuation',
    startNow: 'Start Now',
    companyValuationStartsHere: 'Your company valuation starts here',
    fewQuestionsForValuation:
      "With just a few questions, we'll identify similar companies and get a starting point for your company valuation, which you will then refine with more information",
    letsGo: "LET'S GO!",
    downloadSampleReport: 'Download sample report',
    helpAndSupport: 'If you need any help, or See how it Works Visit the help and support page',
    companyNameValuation: 'Company Name Valuation',
    dashboard: 'Dashboard',
    startedIn: 'Started In:',
    industry: 'Industry:',
    activity: 'Activity',
    employees: 'Employees',
    stage: 'Stage',
    lastRevenues: 'Last Revenues:',
    preMoneyValuation: 'Pre-money Valuation',
    lowBound: 'Low Bound',
    highBound: 'High Bound',
    methodsBreakdown: 'Methods Breakdown',
    Scorecard: 'Scorecard',
    'Check-List': 'Check-List',
    'Venture Capital': 'Venture Capital',
    'DCF Long Term Growth': 'DCF Long Term Growth',
    'DCF with Multiples': 'DCF with Multiples',
    weightOfTheMethods: 'Weight of the methods',
    dcfMethods: 'DCF Methods',
    multiples: 'Multiples',
    longTermGrowth: 'Long Term Growth',
    ventureCapitalMethod: 'Venture capital method',
    assumptions: 'Assumptions',
    exitValue: 'Exit Value',
    mrp: 'MRP',
    wacc: 'WACC',
    lastEBITDA: 'Last EBITDA',
    ebitdaMultiple: 'EBITDA multiple',
    survival: 'Survival',
    failure: 'Failure',
    startNowPrintingReports: 'Start now printing your own reports!',
    downloadProfessionalReports: 'Download professional, clear and detailed, beautifully designed reports in 1 click',
    startNowButton: 'Start now',
    shareholders: 'Shareholders',
    revenueAndCost: 'Revenue and cost',
    revenue: 'Revenue',
    cost: 'Cost',
    rate: 'Rate',
    needHelpOrSeeHowItWorks: 'If you need any help, or See how it Works',
    visitHelpAndSupport: 'Visit the help and support page',
    initialCompanyInfo: 'Initial Company Info',
    questionnaire: 'Questionnaire',
    financialInfo: 'Financial Info',
    generateReport: 'Generate Report',
    start_now: 'Start Now',
  },

  negative: {
    negativeValuation: 'The calculation resulted in a negative valuation',
    dontWorry:
      "Don't worry - we'll help you get back on track. Follow the next steps and you'll be back to normal in no time",
    doubleCheckLastYear:
      'Double check the last projected year. If the EBITDA or cash flows are negative, either add more forecasted years or review revenue and costs',
    doubleCheckProjections:
      'Double check your projections, the future positive cash flows might not compensate for the negative ones. This sometimes is due to the working capital defaults, or projections that might be too conservative',
    consultGuide: 'Consult our in-depth guide on negative valuation',
    contactUsChat: 'Contact us via the chat. We are always happy to help',
    goToFinancial: 'Go to Financial',
  },

  initial: {
    initialCompanyInfo: 'Initial Company Info',
    initialCompanyValuationInformation: 'Initial Company Valuation Information',
    numberOfEmployees: 'Number Of Employees',
    addNumberOfEmployees: 'Add the number of employees in the company',
    foundersNumber: 'Founders number',
    addNumberOfFounders: 'Add the number of founders of the company',
    companyBasedCountry: 'Company Based Country',
    companyIndustry: 'Company Industry',
    incorporationYear: 'Incorporation year',
    companyStage: 'Company Stage',
    ideaStage: 'Idea stage: Working on business plan and testing the initial problem-solution assumptions',
    developmentStage: 'Development stage: Working on the product and setting up operations',
    startupStage: 'Startup stage: Already active with revenues up to',
    expansionStage: 'Expansion stage: Already active with revenues above and looking to scale up the operations',
    growthStage: 'Growth stage: Already active, sizable business with robust, proven, and repeatable growth',
    maturityStage: 'Maturity stage: Already active, profitable business with predictable growth aligned with industry',
    intellectualPropertyApplies: 'Which Kind of intellectual Property (IP) Applies to the product / Service',
    trademarkDomainNames: 'Trademark and/or domain names',
    copyright: 'Copyright',
    patent: 'Patent (excluding US provisional patent)',
    other: 'Other',
    none: 'None',
    companySustainablyBreakeven: 'Is Company Sustainably breakeven?',
    yes: 'Yes',
    no: 'No',
    revenuePastYear: 'Revenue Past year',
    howMuchRevenue: 'How Much Revenue',
    revenueInThreeYears: 'Revenue in three years time',
    next: 'Next',
    previous: 'Previous',
  },
  team: {
    teamInformation: 'Team Information',
    investorsTeamImportance:
      'Investors often regard the team as a key factor in their decisions. Industry experience, complementarity and affinity of the members build up a team strength.',
    reasonForValuation: 'What Is The Reason For The Valuation?',
    fundraisingInvestment: 'Fundraising or investment',
    sellingCompany: 'Selling all or part of the company',
    internalPlanningBudgeting: 'Internal planning and budgeting',
    investorsReporting: 'Investors or Stakeholders reporting',
    taxReportingCompliance: 'Tax reporting and compliance',
    advisoryConsulting: 'Advisory or other consulting purposes',
    other: 'Other',
    relationshipWithCompany: 'What Is Your Relationship With The Company Being Valued?',
    founder: 'Founder',
    employeeFinance: 'Employee from the finance/administration department',
    employeeOtherDepartment: 'Employee from another department',
    externalConsultantAdvisor: 'External consultant and/or advisor',
    currentShareholderNoRole: 'Current shareholder with no operating role',
    interestedInvestorBuyer: 'Interested investor/buyer',
    numberOfFounders: 'Number Of Founders',
    foundersInvestment: 'How Much Did The Founders Invest In The Company In Terms Of Capital Collectively So Far?',
    majorityFoundersInvolvedOtherCompanies:
      'Is The Majority Of The Founders Involved In Other Companies Or Occupations?',
    sideProject: 'Yes, we see the company as a side project to our current occupation',
    partTimeCommitted: 'Yes, but we are committed part time on a continuous basis',
    partTimeCommittedPlanFullTime:
      'Yes, but we are committed part time on a continuous basis and plan to commit full time once the organizational structure of the company is fully operational',
    fullTimeCommitted: 'No, we are committed full time',
    averageAgeOfFounders: 'What Is The Average Age Of The Founders?',
    foundersExperience: 'Has Any Of The Founders Previous Entrepreneurial Experience?',
    firstEntrepreneurialExperience: 'This is the first entrepreneurial experience for all of us',
    previouslyFoundedCompany: 'Yes, at least one of us previously founded a company',
    successfullySoldCompany: 'Yes, and at least one of us successfully sold a company',
    numberOfEmployees: 'Number Of Employees',
    coreTeamDuration: 'How Long Have The Members Of The Core Team Worked (Or Studied) Together?',
    lessThanOneYear: 'Less than 1 year',
    oneToTwoYears: 'Between 1 and 2 years',
    threeToFiveYears: 'Between 3 and 5 years',
    moreThanFiveYears: 'More than 5 years',
    coreTeamExperience: 'How Many Years Of Relevant Industry Experience Does The Core Team Have Collectively?',
    businessManagerialBackground: 'Does Any Member Of The Core Team Have Business And Managerial Background?',
    no: 'No',
    yes: 'Yes',
    businessRelatedStudies: 'At least one of us has accomplished business-related studies',
    workExperience: 'At least one of us has work experience',
    midLevelManager: 'At least one of us has been employed as mid-level manager (e.g. Product manager, Sales manager)',
    topTierManager:
      'At least one of us has been employed as a top-tier manager (VP and above positions) or has held a similar position in his or her own company which had more than 50 employees',
    technicalSkills:
      'Does The Core Team Have All The Main Technical Skills? (E.G. Web Development For Online Services)',
    noButOutsource: 'No, but we outsource all of them',
    someOutsourceRest: 'We have some of the technical capabilities and outsource the rest',
    majorityOutsourceRest: 'We have the majority of the technical capabilities and outsource the rest',
    addNumberOfFounders: 'Add the number of founders of the company',
    next: 'Next',
  },
  business: {
    businessModel: 'Business Model',
    businessModelDetail:
      "Your business model, detailing how your company creates, delivers, and captures value, influences your potential for revenue generation and growth, significantly impacting your company's valuation.",
    isBusinessScalable: 'Is The Business Scalable?',
    yes: 'Yes',
    no: 'No',
    mainBusinessModel: "The Company's Main Business Model Is:",
    b2c: 'B2C (Business to Customers)',
    b2b: 'B2B (Business to Business)',
    stageOfDevelopment: 'Which Stage Of Development Are You Facing Now?',
    ideaStage: 'Idea stage: Working on business plan and testing the initial problem-solution assumptions',
    developmentStage: 'Development stage: Working on the product and setting up operations',
    startupStage: 'Startup stage: Already active with revenues up to 80,000$',
    expansionStage:
      'Expansion stage: Already active with revenues above 80,000$ and looking to scale up the operations',
    growthStage: 'Growth stage: Already active, sizable business with robust, proven, and repeatable growth',
    maturityStage: 'Maturity stage: Already active, profitable business with predictable growth aligned with industry',
    isCompanySustainablyBreakeven: 'Is Company Sustainably Breakeven?',
    doesCompanyHaveBoardOfAdvisors: 'Does The Company Have A Board Of Advisors?',
    externalAdvisors: 'We can count on external advisors but they are not organized in a board yet',
    howManyAdvisors: 'How Many Are The Advisors?',
    indicateShareholders: 'Indicate Which, If Any, Of The Following Entities Are A Shareholder Of The Company',
    friendsAndFamily: 'Friends and Family',
    crowdfunding: 'Crowdfunding',
    incubatorAccelerator: 'Incubator / accelerator',
    businessAngel: 'Business angel',
    ventureCapitalist: 'Venture Capitalist',
    valuableExitStrategies: 'Does The Company Offer Valuable Exit Strategies To Investors?',
    noExitStrategies: 'We did not think about exit strategies so far',
    someExitOpportunities: 'We considered some exit opportunities',
    multipleExitOpportunities: 'We have multiple exit opportunities',
    marketInterest: 'Companies active in the market have already demonstrated interest in buying us',
    bigCompaniesInterest: 'Big companies demonstrated strong interest and willingness to commit to the acquisition',
    next: 'Next',
    previous: 'Previous',
  },
  product: {
    productAndMarket: 'Product and Market',
    productAndMarketDetail:
      "Information about your product/service and target market is essential to the valuation. The product's potential for revenue, cost structure, and uniqueness, along with the size and growth of your market, are key factors in determining the company's value.",
    companyBasedCountry: 'Company Based Country',
    companyIndustry: 'Company Industry',
    productServiceRolloutStage: 'How Would You Define The Stage Of The Product/Service Roll-Out?',
    planning: 'Planning: the roll-out process has still to begin',
    prototype:
      "Prototype: we have the first version of the product (alpha or beta) for testing/display purposes, but it's not ready for the market",
    mvp: 'MVP: We have a minimum viable product (with just enough features to satisfy early customers) released on the market',
    market: 'Market: the complete version of the product is offered to the market',
    demandTested: 'Has The Demand For The Proposed Product/Service Been Tested?',
    noTestingStage: 'No, we are still under testing stage',
    demandValidatedByCompetitors: 'Yes, the demand has been validated by our main competitors',
    extensivelyTestedAndValidated: 'Yes, the demand has been extensively tested and validated',
    tamSize: 'What Is The Current Size Of Your Total Addressable Market (TAM), In Monetary Terms?',
    annualGrowthRate: 'What Is The Annual Growth Rate Of This Market?',
    barriersToEntry:
      'How High Are The Barriers To Entry Of The Market The Company Is Targeting For Potential Competitors?',
    veryLow: 'Very low',
    low: 'Low',
    modest: 'Modest',
    high: 'High',
    veryHigh: 'Very high',
    levelOfCompetition: 'How Would You Define The Level Of Competition In Your Targeted Market?',
    dominatedBySinglePlayer: 'Dominated by single player',
    dominatedBySeveralPlayers: 'Dominated by several players',
    manySmallPlayers: 'Many small players',
    someSmallPlayers: 'Some small players',
    blueOcean: 'Blue ocean (negligible competition)',
    competitiveProducts: 'Existing Competitive Products/Services Are:',
    excellent: 'Excellent',
    good: 'Good',
    weak: 'Weak',
    productComparison: 'How Would You Define Your Product Compared To The Ones Of Existing Competitors?',
    comparable: 'Comparable',
    comparableWithInnovation: 'Comparable, but we innovate in terms of marketing proposition/USP',
    radicalInnovation: 'Comparable, but radically innovate in terms of execution (e.g. costs, manufacturing, etc.)',
    nonComparableSolution: 'Current products solve the same needs with a non comparable solution',
    internationalCompetition: 'How Do You Relate To International Competition?',
    competitionEstablished: 'Competition abroad is already established',
    competitionGrowingButSmaller:
      'Competition abroad is growing but foreign markets are still smaller than the one in our country',
    competitionNotDeveloped: 'Competition abroad is not yet developed',
    internationalExpansion: 'Do You Plan International Expansion?',
    localBusiness: 'We are a locally based business: international expansion is not an option for us',
    bigChallenges: 'International expansion entails big challenges for us',
    consideringInternationalization: 'We are considering internationalization but our focus now is on the local market',
    planForExpansion: 'We already have a plan for international expansion',
    globalFocus: 'Our main strategic focus is already global',
    feedbackTrend: 'How Is The Average Trend Of Feedback Received So Far From Early Adopters/Industry Experts?',
    mainlyNegative: 'Mainly negative',
    fairlyNegative: 'Fairly negative',
    fairlyPositive: 'Fairly positive',
    mainlyPositive: 'Mainly positive',
    allPositive: 'All positive',
    customerLoyalty: 'How Would You Define The Degree Of Loyalty Of Your Customers?',
    stillTesting: 'Still to be tested or under testing',
    lowRetention: 'Low retention: the business model requires time to develop higher retention',
    averageRetention: 'Average retention: already at good level and can be further improved',
    highRetention: 'High retention: high level of loyalty already developed with customers',
    strategicPartners:
      'How Strong Are The Relationships With Key Strategic Partners (Including Distributors, Suppliers)?',
    noPartnersContacted: 'No partners contacted so far',
    identifiedAndContacted: 'We identified and contacted them',
    informalAgreements: 'Informal agreements already in place',
    contractsSigned: 'Contracts signed',
    contractsAndHighVolumes: 'Contracts signed and serving high volumes',
    next: 'Next',
    previous: 'Previous',
  },
  IPAndLegal: {
    ipAndLegal: 'IP and Legal',
    ipAndLegalDetail:
      'Intellectual Property (IP) assets and legal compliance contribute to your competitive advantage, potential revenue, and help assess potential risks and costs to your business.',
    companyStarted: 'When Was The Company Started?',
    legalEntityIncorporated: 'Has A Legal Entity Been Incorporated?',
    yearOfIncorporation: 'Year of incorporation',
    yes: 'Yes',
    no: 'No',
    supportOfLegalConsultants:
      'Can The Company And Its Founders Already Count On The Support Of External Legal Consultants?',
    kindOfIP: 'Which Kind Of Intellectual Property (IP) Applies To The Product / Service?',
    trademarkDomainNames: 'Trademark and/or domain names',
    copyright: 'Copyright',
    patent: 'Patent (excluding US provisional patent)',
    other: 'Other',
    ipDevelopmentStage: 'How Would You Define The Current Stage Of Development Of Your IP?',
    notApplicable: 'Not applicable',
    ipPendingApproval: 'IP pending for approval',
    ipProtectionRegional: 'IP protection secured at regional level',
    ipProtectionGlobal: 'IP protection secured at global level',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
  },

  reports: {
    valuation: 'Valuation',
    generatedReportsHistory: 'Generated Reports History',
    generateNewReport: 'Generate New Report',
    reportName: 'Report Name',
    createdDate: 'Created Date',
    actions: 'Actions',
    reports: 'Reports',
    generateValuationReports: 'Generate valuation reports',
    clearAndDetailedReport:
      'A clear and detailed company valuation report is the best way to communicate your value to potential investors, buyers, stakeholders or employees.',
    reportTitle: 'Report Title',
    generateReport: 'Generate Report',
    next: 'Next',
    cancel: 'Cancel',
    back: 'Back',
    basedOnClass: 'Based on class',
    basedOnShareholders: 'Based on shareholders',
    selectMethod: 'Select Ownership Calculation Method',
    basedOnClassesDescription: 'This will use the classes in the ownership graph',
    basedOnShareholdersDescription: 'This will use the shareholders in the ownership graph',
  },

  financial: {
    financialInfo: 'Financial Info',
    currency: 'Currency',
    valuationDate: 'Valuation Date:',
    resetFinancials: 'reset financials',
    saveProgress: 'Save Progress',
    requiredFields: 'Required Fields',
    pastYear: 'Past Year',
    forecasts: 'Forecasts',
    addNewRecord: 'Add New Record',
    selectDate: 'select date',
    additionalFields: 'Additional Fields',
    enableEditing: 'Enable Editing',
    balanceSheet: 'Balance Sheet',
    balanceSheetDescription:
      "The balance sheet reveals your company's financial status at a specific point in time. You can use the most recent balance sheet from your accountant or calculate your own figures, ideally as of the valuation date.",
    assets: 'Assets',
    liabilities: 'Liabilities',
    disableEditingWarning:
      'Are you sure you want to disable editing? This action will result in data loss, and the system will calculate it automatically.',
    enableEditingWarning:
      'Are you sure you want to enable editing? This action will allow you to add all these input fields manually.',
    losingData: 'Losing Data',
    fillingData: 'Filling Data',
    yesDisableEditing: 'Yes, Disable Editing',
    yesEnableEditing: 'Yes, Enable Editing',
    resetNumbersWarning:
      'Are you sure you want to reset all financial numbers? This action will result in data loss, and the system will calculate it automatically.',
    yesResetNumbers: 'Yes, Reset Numbers',
  },
};
