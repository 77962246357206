export default {
  steps: {
    initialCompanyInfo: 'المعلومات الأساسية للشركة',
    teamInformation: 'معلومات فريق العمل',
    businessModel: 'نموذج العمل',
    productAndMarket: 'المنتج والسوق',
    ipAndLegal: 'الملكية الفكرية والقانونية',
  },

  dashboard: {
    valuation: 'التقييم',
    startNow: 'ابدأ الآن',
    companyValuationStartsHere: 'تقييم شركتك يبدأ هنا',
    fewQuestionsForValuation:
      'مع عدد قليل من الأسئلة، سنحدد الشركات المماثلة ونحصل على نقطة بداية لتقدير قيمة شركتك، التي ستقوم بتقييمها من خلال المزيد من المعلومات',
    letsGo: 'لنبدأ الآن',
    downloadSampleReport: 'تحميل نموذج التقرير',
    helpAndSupport: 'إذا كنت بحاجة إلى أي مساعدة أو التعرف على كيفية العمل، قم بزيارة صفحة المساعدة والدعم',
    companyNameValuation: 'تقييم اسم الشركة',
    dashboard: 'لوحة التحكم',
    startedIn: 'تاريخ البداية',
    industry: 'الصناعة',
    activity: 'النشاط',
    employees: 'الموظفين',
    stage: 'المرحلة',
    lastRevenues: 'الإيرادات الاخيرة',
    preMoneyValuation: 'تقييم ما قبل الاستثمار',
    lowBound: 'الحد الأدني',
    highBound: 'الحد الأعلى',
    methodsBreakdown: 'تصنيف منهجية التقييم',
    Scorecard: 'بطاقة الأداء المتوازن',
    'Check-List': 'قائمة التحقق',
    'Venture Capital': 'الصندوق الاستثماري',
    'DCF Long Term Growth': 'DCF معدل نمو طويل المدى',
    'DCF with Multiples': 'DCF مع مضاعفات',
    weightOfTheMethods: 'أوزان طرق التقييم',
    dcfMethods: 'طرق DCF',
    multiples: 'المضاعفات',
    longTermGrowth: 'نمو طويل المدى',
    ventureCapitalMethod: 'منهجية الصندوق الاستثماري',
    assumptions: 'الافتراضات',
    exitValue: 'قيمة التخارج',
    mrp: 'MRP',
    wacc: 'WACC',
    lastEBITDA: 'EBITDA',
    ebitdaMultiple: 'مضاعف EBITDA',
    survival: 'حالة النجاة',
    failure: 'الإخفاق',
    startNowPrintingReports: 'ابدأ الآن في طباعة تقاريرك الخاصة!',
    downloadProfessionalReports: 'قم بتحميل التقارير الرسمية والموضحة بالتفصيل بتصميم رائع وبنقرة واحدة!',
    startNowButton: 'ابدأ الآن',
    shareholders: 'المساهمين',
    revenueAndCost: 'الإيرادات والتكلفة',
    revenue: 'الإيرادات',
    cost: 'التكلفة',
    rate: 'معدل',
    needHelpOrSeeHowItWorks: 'إذا كنت بحاجة إلى أي مساعدة أو التعرف على كيفية العمل',
    visitHelpAndSupport: 'قم بزيارة صفحة المساعدة والدعم',
    initialCompanyInfo: 'المعلومات الأساسية للشركة',
    questionnaire: 'الاستبيان',
    financialInfo: 'معلومات مالية',
    generateReport: 'اصدر التقرير',
    start_now: 'ابدأ الأن',
  },

  negative: {
    negativeValuation: 'نتيجة الاحتساب ادت إلى تقييم سلبي!',
    dontWorry:
      'لا تقلق - سنساعدك على العودة إلى المسار الصحيح. اتبع الخطوات التالية وستعود إلى الوضع الطبيعي في وقت قصير.',
    doubleCheckLastYear:
      'تحقق مرة أخرى من السنة المتوقعة الأخيرة. إذا كانت الأرباح قبل الفوائد والضرائب والاستهلاك (EBITDA) أو التدفقات النقدية سلبية، فإما أن تقوم بإضافة المزيد من السنوات المتوقعة أو بمراجعة الإيرادات والتكاليف.',
    doubleCheckProjections:
      'تحقق مرة أخرى من توقعاتك، فقد لا تعوض التدفقات النقدية الإيجابية المستقبلية عن التدفقات السلبية. ويرجع ذلك في بعض الأحيان إلى عجز رأس المال العامل، أو قد تكون التوقعات محافظة بشكل كبير',
    consultGuide: 'اطلع على دليلنا الشامل حول التقييم السلبي',
    contactUsChat: 'تواصل معنا عبر الدردشة. نحن دائما سعداء بتقديم المساعدة',
    goToFinancial: 'انتقل إلى القسم المالي',
  },

  initial: {
    initialCompanyInfo: 'المعلومات الأساسية للشركة',
    initialCompanyValuationInformation: 'بيانات التقييم الأولية للشركة',
    numberOfEmployees: 'عدد الموظفين',
    addNumberOfEmployees: 'أضف عدد الموظفين في الشركة',
    foundersNumber: 'عدد المؤسسين',
    addNumberOfFounders: 'أضف عدد مؤسسي الشركة',
    companyBasedCountry: 'مقر الشركة (الدولة)',
    companyIndustry: 'صناعة الشركة',
    incorporationYear: 'تاريخ التأسيس',
    companyStage: 'مرحلة الشركة',
    ideaStage: 'الفكرة: العمل على خطة العمل واختبار الافتراضات الأولية للمشكلة والحل',
    developmentStage: 'التطوير: العمل على المنتج وإعداد العمليات',
    startupStage: 'مرحلة البدء: نشطة بالفعل مع إيرادات وصلت إلى',
    expansionStage: 'مرحلة التوسع: نشطة بالفعل مع إيرادات مرتفعة ونبحث عن توسيع العمليات',
    growthStage: 'مرحلة النمو: نشطة بالفعل، ونمتلك نشاط تجاري كبير مع نمو ثابت وقابل للتكرار',
    maturityStage: 'مرحلة النضج: نشطة بالفعل، وتمتلك عملية ربحية مع نمو متوقع متناسق مع الصناعة',
    intellectualPropertyApplies: 'أي نوع من الملكية الفكرية ينطبق على المنتج / الخدمة؟',
    trademarkDomainNames: 'علامة تجارية و / أو أسماء نطاق',
    copyright: 'حقوق الملكية',
    patent: 'براءة اختراع (باستثناء براءة اختراع مؤقتة أمريكية)',
    other: 'أخرى',
    none: 'لا يوجد',
    companySustainablyBreakeven: 'هل الشركة متوازنة بشكل مستمر؟',
    yes: 'نعم',
    no: 'لا',
    revenuePastYear: 'إيرادات العام الماضي',
    howMuchRevenue: 'كم كانت الإيرادات',
    revenueInThreeYears: 'الإيرادات خلال ثلاث سنوات',
    next: 'التالي',
    previous: 'السابق',
  },
  team: {
    teamInformation: 'معلومات فريق العمل',
    investorsTeamImportance:
      'غالبًا ما يعتبر المستثمرون فريق العمل عاملاً رئيسيًا في قراراتهم. الخبرة وتآلف أعضاء الفريق تبني قوة الفريق.',
    reasonForValuation: 'ما هو سبب التقييم؟',
    fundraisingInvestment: 'جمع التمويل او الاستثمارات',
    sellingCompany: 'بيع كلي او جزئي للشركة',
    internalPlanningBudgeting: 'التخطيط والمزانية الداخلية',
    investorsReporting: 'تقارير المستثمرين أو أصحاب المصلحة',
    taxReportingCompliance: 'تقارير الضرائب والامتثال',
    advisoryConsulting: 'الاستشارات',
    other: 'أخرى',
    relationshipWithCompany: 'ما هي علاقتك مع الشركة التي يتم تقييمها؟',
    founder: 'مؤسس',
    employeeFinance: 'موظف في قسم الشؤون المالية / الإدارية',
    employeeOtherDepartment: 'موظف في قسم آخر',
    externalConsultantAdvisor: 'استشاري خارجي و / أو مستشار',
    currentShareholderNoRole: 'مساهم حالي بدور تشغيلي',
    interestedInvestorBuyer: 'مستثمر / مشتري مهتم',
    numberOfFounders: 'عدد المؤسسين',
    foundersInvestment: 'كم مجموع الأموال التي استثمرها المؤسسون في الشركة حتى الآن؟',
    majorityFoundersInvolvedOtherCompanies: 'هل يشارك معظم المؤسسين في شركات أو مهن أخرى؟',
    sideProject: 'نعم، نعتبر الشركة مشروعًا جانبيًا لعملنا الحالي',
    partTimeCommitted: 'نعم، لكننا ملتزمون بدوام جزئي بشكل مستمر',
    partTimeCommittedPlanFullTime:
      'نعم، لكننا ملتزمون بدوام جزئي بشكل مستمر ونخطط للالتزام بدوام كامل بمجرد أن تصبح الهيكلة التنظيمية للشركة تعمل بكامل طاقتها',
    fullTimeCommitted: 'لا، نحن ملتزمون بدوام كامل',
    averageAgeOfFounders: 'ما هو متوسط عمر المؤسسين؟',
    foundersExperience: 'هل لأي من المؤسسين خبرة سابقة في ريادة الأعمال؟',
    firstEntrepreneurialExperience: 'هذه هي التجربة الريادية الأولى بالنسبة لنا جميعًا',
    previouslyFoundedCompany: 'نعم، على الأقل واحد منا سبق أن أسس شركة',
    successfullySoldCompany: 'نعم، وعلى الأقل واحد منا باع شركة بنجاح',
    numberOfEmployees: 'عدد الموظفين',
    coreTeamDuration: 'ما المدة التي عمل فيها أعضاء الفريق الأساسي (أو درسوا) معًا؟',
    lessThanOneYear: 'أقل من سنة',
    oneToTwoYears: 'ما بين سنة أو سنتين',
    threeToFiveYears: 'ما بين 3 أو 5 سنوات',
    moreThanFiveYears: 'أكثر من 5 سنوات',
    coreTeamExperience: 'ما عدد سنوات الخبرة ذات الصلة التي يتمتع بها الفريق الأساسي بشكل جماعي؟',
    businessManagerialBackground: 'هل يتمتع أي عضو في الفريق الأساسي بخلفية تجارية أو إدارية؟',
    no: 'لا',
    yes: 'نعم',
    businessRelatedStudies: 'على الأقل واحد منا قد أكمل دراسات تجارية ذات صلة',
    workExperience: 'على الأقل واحد منا لديه خبرة',
    midLevelManager: 'على الأقل واحد منا كان موظفًا كمدير في المستوى المتوسط (مثل مدير المنتج، مدير المبيعات)',
    topTierManager:
      'على الأقل واحد منا كان موظفًا كمدير من الإدارات العليا (مناصب مثل نائب الرئيس وأعلى) أو كان لديه وظيفة مماثلة في شركته الخاصة التي كانت تضم أكثر من 50 موظفًا',
    technicalSkills:
      'هل يمتلك الفريق الأساسي جميع المهارات التقنية الرئيسية؟ (مثل تطوير موقع الويب للخدمات عبر الإنترنت)',
    noButOutsource: 'لا، لكننا نستعين بخدمات خارجية لها جميعها',
    someOutsourceRest: 'لدينا بعض القدرات التقنية ونستعين بالبقية',
    majorityOutsourceRest: 'لدينا معظم القدرات التقنية ونستعين بالبقية',
    addNumberOfFounders: 'أضف عدد مؤسسي الشركة',
    next: 'التالي',
  },
  business: {
    businessModel: 'نموذج العمل',
    businessModelDetail:
      'يؤثر نموذج عملك، الذي يوضح كيفية إنشاء وتقديم والتقاط القيمة من شركتك، على إمكاناتك في توليد الإيرادات والنمو، مما يؤثر بشكل كبير على تقييم شركتك.',
    isBusinessScalable: 'هل العمل قابل للتوسع؟',
    yes: 'نعم',
    no: 'لا',
    mainBusinessModel: 'النموذج التجاري الرئيسي للشركة هو:',
    b2c: 'B2C (من الشركة إلى العملاء)',
    b2b: 'B2B (من الشركة إلى الشركة)',
    stageOfDevelopment: 'ماهي مراحل التطوير التي تواجهها الان:',
    ideaStage: 'مرحلة الفكرة: العمل على خطة العمل واختبار الافتراضات الأولية للمشكلة والحل',
    developmentStage: 'مرحلة التطوير: العمل على المنتج وتجهيز العمليات',
    startupStage: 'مرحلة البدء: نشطة مع ايردات تصل الى 80,000$',
    expansionStage: 'مرحلة التوسع: نشطة مع إيرادات تتجاوز 80,000$ ونبحث عن توسيع العمليات',
    growthStage: 'مرحلة النمو: نشطة بالفعل، ونمتلك نشاط تجاري كبير مع نمو ثابت وقابل للتكرار',
    maturityStage: 'مرحلة النضج: نشطة بالفعل، وتمتلك عملية ربحية مع نمو متوقع متناسق مع الصناعة',
    isCompanySustainablyBreakeven: 'هل الشركة متوازنة بشكل مستمر؟',
    doesCompanyHaveBoardOfAdvisors: 'هل لدى الشركة مجلس مستشارين؟',
    externalAdvisors: 'يمكننا الاعتماد على المستشارين الخارجيين ولكنهم لم يتم تنظيمهم في مجلس الإدارة بعد',
    howManyAdvisors: 'كم عدد المستشارين؟',
    indicateShareholders: 'حدد الكيانات التي تشترك في رأس المال في الشركة، إن وجدت',
    friendsAndFamily: 'أصدقاء آو عائلة',
    crowdfunding: 'تمويل جماعي',
    incubatorAccelerator: 'حاضنة / مسرعة أعمال',
    businessAngel: 'مستثمرين ملائكيين',
    ventureCapitalist: 'صندوق استثماري',
    valuableExitStrategies: 'هل تقدم الشركة استراتيجيات تخارج قيمة للمستثمرين؟',
    noExitStrategies: 'لم نفكر في استراتيجيات التخارج حتى الآن',
    someExitOpportunities: 'لقد حددنا بعض فرص التخارج',
    multipleExitOpportunities: 'لدينا العديد من الفرص للتخارج',
    marketInterest: 'لقد أبدت الشركات النشطة في السوق اهتمامًا بشرائنا',
    bigCompaniesInterest: 'أبدت الشركات الكبرى اهتمامًا قويًا واستعدادًا لعملية الاستحواذ',
    next: 'التالي',
    previous: 'السابق',
  },
  product: {
    productAndMarket: 'المنتج والسوق',
    productAndMarketDetail:
      'تُعد المعلومات المتعلقة بمنتجك او خدمتك والسوق المستهدف أمراً ضرورياً للتقييم. حيث أن إمكانية المنتج لتحقيق الإيرادات، وهيكل التكلفة، والتفرد، إلى جانب حجم السوق ونموه، تعبر عوامل رئيسية في تحديد قيمة الشركة.',
    companyBasedCountry: 'مقر الشركة (الدولة)',
    companyIndustry: 'صناعة الشركة',
    productServiceRolloutStage: 'كيف يمكنك تحديد مرحلة طرح المنتج او الخدمة؟',
    planning: 'التخطيط: عملية الإطلاق لم تبدأ بعد',
    prototype:
      'النموذج الأولي: لدينا الصدار الأول من المنتج (alpha or beta) لأغراض الاختبار أو العرض، لكنه غير جاهز للسوق',
    mvp: 'لدينا الحد الأدنى من المنتج القابل للتطبيق (مع ميزات كافية لإرضاء العملاء الأوائل) تم طرحه في السوق',
    market: 'السوق: يتم طرح النسخة الكاملة للمنتج في السوق',
    demandTested: 'هل تم اختبار الطلب على المنتج او الخدمة المقترحة؟',
    noTestingStage: 'لا، نحن لا زلنا في مرحلة الاختبار',
    demandValidatedByCompetitors: 'نعم، تم التحقق من الطلب من قبل منافسينا الرئيسيين',
    extensivelyTestedAndValidated: 'نعم، لقد تم اختبار الطلب والتحقق من طلب المنتج او الخدمة على نطاق واسع',
    tamSize: 'ما هو حجم السوق الكلي المتاح الحالي (TAM)، كارقام؟',
    annualGrowthRate: 'ما هو معدل النمو السنوي لهذا السوق؟',
    barriersToEntry: 'ما مدى صعوبة دخول السوق الذي تستهدفه الشركة للمنافسين المحتملين؟',
    veryLow: 'منخفض جدًا',
    low: 'منخفض',
    modest: 'متوسط',
    high: 'عالي',
    veryHigh: 'عال جدًا',
    levelOfCompetition: 'كيف يمكنك تحديد مستوى المنافسة في السوق المستهدف؟',
    dominatedBySinglePlayer: 'يسيطر عليه واحد فقط',
    dominatedBySeveralPlayers: 'تسيطر عليه عدة شركات',
    manySmallPlayers: 'العديد من الشركات الصغيرة',
    someSmallPlayers: 'بعض الشركات الصغيرة',
    blueOcean: 'لا توجد منافسة (محيط ازرق)',
    competitiveProducts: 'المنتجات او الخدمات التنافسية الموجودة هي:',
    excellent: 'ممتازة',
    good: 'جيدة',
    weak: 'ضعيفة',
    productComparison: 'كيف يمكنك تحديد منتجك مقارنة بمنتجات المنافسين الحاليين؟',
    comparable: 'قابل للمقارنة',
    comparableWithInnovation: 'قابل للمقارنة، لكننا نبتكر من حيث الاقتراح / القيمة الفريدة لنقاط البيع',
    radicalInnovation:
      'قابلة للمقارنة، ولكننا نبتكر بشكل جذري من حيث التنفيذ (على سبيل المثال، التكاليف، التصنيع، وما إلى ذلك)',
    nonComparableSolution: 'المنتجات الحالية تحل نفس الاحتياجات بحل غير قابل للمقارنة',
    internationalCompetition: 'كيف تتعامل مع المنافسة بالسوق العالمي؟',
    competitionEstablished: 'بدانا بالمنافسة في السوق العالمي بالفعل',
    competitionGrowingButSmaller:
      'المنافسة في السوق العالمي تنمو ولكن الأسواق الخارجية لا تزال أصغر من تلك التي في بلدنا',
    competitionNotDeveloped: 'السوق العالمية لم تتطور بعد',
    internationalExpansion: 'هل تخطط للتوسع دولياً؟',
    localBusiness: 'نحن شركة محلية: التوسع خارجياً ليس خيارًا لنا',
    bigChallenges: 'التوسع بالأسواق الخارجية ينطوي عليه تحديات كبيرة بالنسبة لنا',
    consideringInternationalization: 'نحن نفكر في التوسع ولكن تركيزنا الآن على السوق المحلي',
    planForExpansion: 'لدينا بالفعل خطة للتوسع بالأسواق الخارجية',
    globalFocus: 'تركيزنا الاستراتيجي الرئيسي هو بالفعل على النطاق الدولي',
    feedbackTrend: 'ما هو اتجاه التعليقات والملاحظات من المبادرين الاولين وخبراء الصناعة حتى الآن؟',
    mainlyNegative: 'سلبية بشكل رئيسي',
    fairlyNegative: 'سلبية بشكل نسبي',
    fairlyPositive: 'إيجابية بشكل نسبي',
    mainlyPositive: 'إيجابية بشكل رئيسي',
    allPositive: 'إيجابية كلها',
    customerLoyalty: 'كيف يمكنك تحديد درجة ولاء عملائك؟',
    stillTesting: 'لا تزال تحت الاختبار أو تحت التجربة',
    lowRetention: 'منخفض: يتطلب نموذج العمل وقتًا لتطوير الاحتفاظ بالعملاء',
    averageRetention: 'متوسط: مستوى جيد بالفعل ويمكن تحسينه بشكل أكبر',
    highRetention: 'مرتفع: تم بالفعل تطوير مستوى عال من الولاء مع العملاء',
    strategicPartners: 'ما مدى قوة العلاقة بينك وبين الشركاء الاستراتيجيين الرئيسيين (بما في ذلك الموزعين والموردين)؟',
    noPartnersContacted: 'لم يتم الاتصال بالشركاء حتى الآن',
    identifiedAndContacted: 'لقد تم التعرف عليهم والاتصال بهم',
    informalAgreements: 'اتفاقيات غير رسمية بالفعل مكنت من الحالة',
    contractsSigned: 'تم توقيع العقود',
    contractsAndHighVolumes: 'تم توقيع العقود وتقديم كميات عالية',
    next: 'التالي',
    previous: 'السابق',
  },
  IPAndLegal: {
    ipAndLegal: 'الملكية الفكرية والقانونية',
    ipAndLegalDetail:
      'تساهم أصول الملكية الفكرية والامتثال القانوني في تحقيق الميزة التنافسية، وتحقيق الإيرادات المحتملة، وتساعد في تقدير المخاطر والتكاليف المحتملة لعملك.',
    companyStarted: 'متى بدأت الشركة؟',
    legalEntityIncorporated: 'هل تم تأسيس كيان قانوني؟',
    yearOfIncorporation: 'تاريخ التأسيس',
    yes: 'نعم',
    no: 'لا',
    supportOfLegalConsultants: 'هل يمكن للشركة ومؤسسيها الاعتماد على دعم المستشارين القانونيين الخارجيين؟',
    kindOfIP: 'أي نوع من الملكية الفكرية ينطبق على المنتج أو الخدمة؟',
    trademarkDomainNames: 'علامة تجارية و/أو أسماء نطاق',
    copyright: 'حقوق الملكية',
    patent: 'براءة اختراع (باستثناء براءة اختراع مؤقتة أمريكية)',
    other: 'أخرى',
    ipDevelopmentStage: 'كيف يمكنك تحديد المرحلة الحالية لتطوير الملكية الفكرية الخاصة بك؟',
    notApplicable: 'غير مطبق',
    ipPendingApproval: 'في انتظار الموافقة على الملكية الفكرية',
    ipProtectionRegional: 'تأمين حماية الملكية الفكرية على المستوى الإقليمي',
    ipProtectionGlobal: 'تأمين حماية الملكية الفكرية على المستوى العالمي',
    previous: 'السابق',
    next: 'التالي',
    submit: 'إرسال',
  },

  reports: {
    valuation: 'التقييم',
    generatedReportsHistory: 'سجل التقارير المنشاة',
    generateNewReport: 'إنشاء تقرير جديد',
    reportName: 'اسم التقرير',
    createdDate: 'تاريخ الانشاء',
    actions: 'خيارات',
    reports: 'التقارير',
    generateValuationReports: 'اصدر تقارير التقييم',
    clearAndDetailedReport:
      'يعد تقرير تقييم الشركة الواضح والمفصل هو أفضل طريقة لتوصيل قيمتك إلى المستثمرين المحتملين أو المشترين أو أصحاب المصلحة أو الموظفين.',
    reportTitle: 'عنوان التقرير',
    generateReport: 'اصدر التقرير',
    next: 'التالي',
    cancel: 'إلغاء',
    back: 'السابق',
    basedOnClass: 'اعتمادا على الفئة',
    basedOnShareholders: 'اعتمادا على المساهمين',
    selectMethod: 'اختر طريقة حساب الملكية',
    basedOnClassesDescription: 'هذا الخيار سوف يستخدم الفئات في التقرير',
    basedOnShareholdersDescription: 'هذا الخيار سوف يستخدم المساهمين في التقرير',
  },

  financial: {
    financialInfo: 'معلومات مالية',
    currency: 'العملة',
    valuationDate: 'تاريخ التقييم:',
    resetFinancials: 'إعادة تعيين البيانات المالية',
    saveProgress: 'حفظ البيانات',
    requiredFields: 'الحقول المطلوبة',
    pastYear: 'السنة الماضية',
    forecasts: 'التوقعات',
    addNewRecord: 'إضافة سجل جديد',
    selectDate: 'تحديد التاريخ',
    additionalFields: 'حقول إضافية',
    enableEditing: 'تمكين التحرير',
    balanceSheet: 'الميزانية العمومية',
    balanceSheetDescription:
      'تكشف الميزانية العمومية وضع شركتك المالي في نقطة زمنية معينة. يمكنك استخدام أحدث الميزانيات العمومية من محاسبك أو حساب الأرقام الخاصة بك في الوقت المثالي بتاريخ التقييم.',
    assets: 'الأصول',
    liabilities: 'المطلوبات',
    disableEditingWarning:
      'هل أنت متأكد أنك تريد تعطيل التحرير؟ سيؤدي هذا الإجراء إلى فقدان البيانات وسيحسب النظام ذلك تلقائيًا.',
    enableEditingWarning:
      'هل أنت متأكد أنك تريد تمكين التحرير؟ سيسمح لك هذا الإجراء بإضافة جميع حقول الإدخال هذه يدويًا.',
    losingData: 'فقدان البيانات',
    fillingData: 'ملء البيانات',
    yesDisableEditing: 'نعم، تعطيل التحرير',
    yesEnableEditing: 'نعم، تمكين التحرير',
    resetNumbersWarning:
      'هل أنت متأكد أنك تريد إعادة تعيين جميع الأرقام المالية؟ سيؤدي هذا الإجراء إلى فقدان البيانات وسيحسب النظام ذلك تلقائيًا.',
    yesResetNumbers: 'نعم، إعادة تعيين الأرقام',
  },
};
